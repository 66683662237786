<template>
  <div class="charts-container">
    <div class="charts-header">
      <div class="charts-header-left">
        <img
          class="charts-icon"
          src="../../assets/images/index.png"
          alt="返回首页"
        />
        <span
          style="margin-left: 10px; cursor: pointer"
          class="breadcrumb-font"
          @click="$router.push({ path: '/dashboards' })"
          >返回首页</span
        >
        <span style="margin-left: 6px; color: #999" class="breadcrumb-font"
          >></span
        >
        <span
          style="margin-left: 6px; color: #999"
          class="breadcrumb-font scene"
          >{{ singeDeviceStatus.scene }}</span
        >
        <!--        <el-switch-->
        <!--          :value="true"-->
        <!--          disabled-->
        <!--          active-color="#01cfa5"-->
        <!--          style="margin-left: 35px"-->
        <!--        ></el-switch>-->
        <span
          :class="
            singeDeviceStatus.activeStatus === 1 ? 'power-on' : 'power-off'
          "
        ></span>
        <span style="color: #666; margin-left: 6px" class="breadcrumb-font">{{
          singeDeviceStatus.activeStatus === 1 ? "新风运行中" : "新风机已关闭"
        }}</span>
        <span class="air-state">空气清新</span>
        <span class="breadcrumb-font" style="color: #333; margin-left: 20px"
          >维护状态：{{ singeDeviceStatus.maintain }}</span
        >
        <!--        <el-select-->
        <!--          v-model="state"-->
        <!--          placeholder="请选择"-->
        <!--          size="mini"-->
        <!--          class="last-select"-->
        <!--        >-->
        <!--          <el-option-->
        <!--            v-for="item in ['已申请', '未申请']"-->
        <!--            :key="item"-->
        <!--            :label="item"-->
        <!--            :value="item"-->
        <!--          >-->
        <!--          </el-option>-->
        <!--        </el-select>-->
      </div>
      <div class="charts-header-right">
        <span class="breadcrumb-font" style="color: #333">新风设置：</span>
        <el-radio-group
          v-model="newSingeDeviceStatus.activeStatus"
          size="small"
          text-color="#333"
          fill="#1594fd"
        >
          <el-radio :label="1" class="first-radio">开机</el-radio>
          <el-radio :label="0">关机</el-radio>
        </el-radio-group>
        <span class="breadcrumb-font" style="color: #333; margin-left: 20px"
          >模式：</span
        >
        <el-select
          v-model="newSingeDeviceStatus.workPattern"
          placeholder="请选择"
          size="mini"
          :disabled="!newSingeDeviceStatus.activeStatus"
        >
          <el-option
            v-for="item in newSingeDeviceStatus.deviceModel === 2
              ? modeOptions
              : modeOptions2"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="breadcrumb-font" style="color: #333; margin-left: 20px"
          >风速：</span
        >
        <el-select
          v-model="newSingeDeviceStatus.windOut"
          placeholder="请选择"
          size="mini"
          :disabled="
            newSingeDeviceStatus.workPattern !== 3 ||
            !newSingeDeviceStatus.activeStatus
          "
        >
          <el-option
            v-for="item in newSingeDeviceStatus.deviceModel === 2
              ? windOptions
              : windOptions2"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="breadcrumb-font" style="color: #333; margin-left: 20px"
          >定时：</span
        >
        <el-switch
          v-model="newSingeDeviceStatus.timingStatus"
          active-color="#13ce66"
          inactive-color="#999999"
          :disabled="!newSingeDeviceStatus.activeStatus"
        >
        </el-switch>
        <span :style="{ color: '#999', fontSize: '14px', marginLeft: '4px' }">{{
          newSingeDeviceStatus.timingStatus ? "开启" : "关闭"
        }}</span>
        <span class="confirm-button" @click="setDevice">确定</span>
      </div>
    </div>
    <div class="charts-search">
      <div class="btns" v-loading="dateSelectLoading">
        <span
          :class="[selected === 1 ? 'btn-selected' : '']"
          @click="
            selected = 1;
            getRangeData(0);
          "
          >日</span
        >
        <span
          :class="[selected === 2 ? 'btn-selected' : '']"
          @click="
            selected = 2;
            getRangeData(7);
          "
          >周</span
        >
        <span
          :class="[selected === 3 ? 'btn-selected' : '']"
          @click="
            selected = 3;
            getRangeData(30);
          "
          >月</span
        >
      </div>
      <div class="date-search">
        <span style="font-size: 14px; color: #666">选择时间</span>
        <el-date-picker
          v-model="datePick"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin-left: 16px; width: 320px"
          size="mini"
        >
        </el-date-picker>
        <!--        <el-select-->
        <!--          v-model="type"-->
        <!--          placeholder="请选择"-->
        <!--          size="mini"-->
        <!--          style="width: 100px; margin-left: 20px"-->
        <!--        >-->
        <!--          <el-option-->
        <!--            v-for="item in fieldList"-->
        <!--            :key="item.id"-->
        <!--            :label="item.label"-->
        <!--            :value="item.value"-->
        <!--          >-->
        <!--          </el-option>-->
        <!--        </el-select>-->
        <span class="date-search-btn" @click="searchRangeData">查询</span>
      </div>
    </div>
    <div class="charts-boxes">
      <div class="left-boxes">
        <bar-echarts
          :Echartsdata="PM25"
          :style="echartsBox"
          v-loading="loadingOptions.PM25Loading"
          :active="dateBlockStatus || singeDateBlockStatus.PM25Status"
        ></bar-echarts>
        <bar-echarts
          :Echartsdata="CO2"
          :style="echartsBox"
          v-loading="loadingOptions.CO2Loading"
          :active="dateBlockStatus || singeDateBlockStatus.CO2Status"
        ></bar-echarts>
        <line-echarts
          :Echartsdata="TVOC"
          :style="echartsBox"
          v-loading="loadingOptions.TVOCLoading"
          :active="dateBlockStatus || singeDateBlockStatus.TVOCStatus"
        ></line-echarts>
        <wendu-line-echarts
          :Echartsdata="wendu"
          :style="echartsBox"
          v-loading="loadingOptions.wenduLoading"
          :active="dateBlockStatus || singeDateBlockStatus.wenduStatus"
        ></wendu-line-echarts>
        <shidu-echarts
          :Echartsdata="shidu"
          :style="echartsBox"
          v-loading="loadingOptions.shiduLoading"
          :active="dateBlockStatus || singeDateBlockStatus.shiduStatus"
        ></shidu-echarts>
        <jiaquan-echarts
          :Echartsdata="jiaquan"
          :style="echartsBox"
          v-loading="loadingOptions.jiaquanLoading"
          :active="dateBlockStatus || singeDateBlockStatus.jiaquanStatus"
        ></jiaquan-echarts>
      </div>
      <div class="right-boxes">
        <filter-state></filter-state>
      </div>
    </div>
    <width-dialog
      :property="dialogProperty"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      width="420px"
    >
      <span
        >此操作将设置{{ singeDeviceStatus.scene }}的新风设备，是否继续？</span
      >
    </width-dialog>
    <chart-dialog
      :chartProperty="chartDialogProperty"
      :chartDialogShow="chartDialogShow"
      :chart-data="chartDialogData"
      :footer="chartDialogFooter"
      @closeChartDialog="closeChartDialog"
      @changeTitle="chartDialogChangeTitle"
    >
    </chart-dialog>
  </div>
</template>

<script>
import {
  FilterState,
  BarEcharts,
  LineEcharts,
  wenduLineEcharts,
  shiduEcharts,
  jiaquanEcharts,
} from "../../components/widget";
import ChartDialog from "../../components/dialog/ChartDialog";
import bus from "@/utils/bus";
import WidthDialog from "../../components/dialog/WidthDialog";
export default {
  name: "Charts",
  components: {
    FilterState,
    BarEcharts,
    LineEcharts,
    wenduLineEcharts,
    shiduEcharts,
    jiaquanEcharts,
    WidthDialog,
    ChartDialog,
  },
  data() {
    return {
      //
      singeDateBlockStatus: {
        PM25Status: 1,
        CO2Status: 1,
        TVOCStatus: 1,
        wenduStatus: 1,
        shiduStatus: 1,
        jiaquanStatus: 1,
      },
      //上一次请求的block 0/7/30
      lastRequestBlock: null,
      //日周月选择loading
      dateSelectLoading: false,
      //block日周月状态 1/2/3
      dateBlockStatus: 1,
      loadingOptions: {
        PM25Loading: false,
        CO2Loading: false,
        wenduLoading: false,
        TVOCLoading: false,
        shiduLoading: false,
        jiaquanLoading: false,
      },
      //单个设备状态
      singeDeviceStatus: {
        scene: "新风系统",
        activeStatus: 0,
        maintain: "正常",
        timingStatus: true,
        windOut: 0,
        workPattern: 3,
      },
      newSingeDeviceStatus: {
        scene: "新风系统",
        activeStatus: 0,
        maintain: "正常",
        timingStatus: true,
        windOut: 0,
        workPattern: 3,
      },
      modeOptions: [
        {
          label: "自动",
          value: 1,
        },
        {
          label: "睡眠",
          value: 4,
        },
        {
          label: "手动",
          value: 3,
        },
      ],
      modeOptions2: [
        {
          label: "睡眠",
          value: 4,
        },
        {
          label: "手动",
          value: 3,
        },
      ],
      windOptions: [
        // {
        //   label: "0挡",
        //   value: 0,
        // },
        {
          label: "1挡",
          value: 1,
        },
        {
          label: "2挡",
          value: 2,
        },
        {
          label: "3挡",
          value: 3,
        },
      ],
      windOptions2: [
        {
          label: "1挡",
          value: 1,
        },
        {
          label: "2挡",
          value: 2,
        },
        {
          label: "3挡",
          value: 3,
        },
        {
          label: "4挡",
          value: 4,
        },
        {
          label: "5挡",
          value: 5,
        },
        {
          label: "6挡",
          value: 6,
        },
        {
          label: "7挡",
          value: 7,
        },
        {
          label: "8挡",
          value: 8,
        },
        {
          label: "9挡",
          value: 9,
        },
        {
          label: "10挡",
          value: 10,
        },
      ],
      chartDialogFooter: "",
      chartDialogShow: false,
      chartDialogProperty: {
        show: false,
        loading: false,
      },
      deviceName: "",
      dialogProperty: {
        show: false,
        title: "新风设置",
      },
      switchStatus: true,
      // radio: "1",
      // mode: "智能",
      // power: 1,
      // wind: "1挡",
      state: "已申请",
      selected: 1,
      datePick: "",
      type: "所有指数",
      PM25: {
        name: "PM2.5",
        x: [],
        y: [],
        color: "#70e1f1",
        nowValue: 0,
        id: "pm25",
      },
      CO2: {
        name: "CO2",
        x: [],
        y: [],
        color: "#41c057",
        id: "co2",
        nowValue: 600,
      },
      TVOC: {
        name: "TVOC",
        x: [],
        y: [],
        id: "tvoc",
        nowValue: 100,
      },
      wendu: {
        name: "温度",
        x: ["3.25", "3.26"],
        yh: [],
        yd: [],
        id: "wendu",
        nowValue: 25,
      },
      shidu: {
        name: "湿度",
        x: [],
        y: [],
        id: "shidu",
      },
      jiaquan: {
        name: "甲醛",
        x: [],
        y: [],
        id: "jiaquan",
        nowValue: 25,
      },
      echartsBox: {
        width: "400px",
        height: "260px",
        boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.05)",
        marginRight: "34px",
        marginBottom: "20px",
      },
      fieldList: [
        {
          id: 1,
          label: "所有指数",
          value: 1,
        },
        {
          id: 2,
          label: "温度",
          value: "wendu",
        },
        {
          id: 3,
          label: "PM2.5",
          value: "PM25",
        },
        {
          id: 4,
          label: "CO2",
          value: "CO2",
        },
        {
          id: 5,
          label: "TVOC",
          value: "TVOC",
        },
        {
          id: 6,
          label: "湿度",
          value: "shidu",
        },
      ],
      chartDialogData: {
        xArray: [],
        yArray: [],
      },
    };
  },
  methods: {
    // 点击上方日周月同步到下方
    changeSingeActive(active) {
      Object.keys(this.singeDateBlockStatus).forEach((item) => {
        this.singeDateBlockStatus[item] = active;
      });
    },
    getRangeData(num) {
      if (this.lastRequestBlock === num) return;
      this.lastRequestBlock = num;
      let frequency = 1;
      if (num === 0) {
        //日
        this.dateBlockStatus = 1;
        this.changeSingeActive(1);
      } else if (num === 7) {
        //周
        this.dateBlockStatus = 2;
        this.changeSingeActive(2);
        frequency = 24;
      } else {
        //月
        this.dateBlockStatus = 3;
        this.changeSingeActive(3);
        frequency = 24;
      }
      this.dateSelectLoading = true;
      Promise.all([
        this.getHistoryData("PM25", frequency, num),
        this.getHistoryData("wendu", frequency, num),
        this.getHistoryData("shidu", frequency, num),
        this.getHistoryData("TVOC", frequency, num),
        this.getHistoryData("CO2", frequency, num),
        this.getHistoryData("jiaquan", frequency, num),
      ])
        .then(() => {
          this.dateSelectLoading = false;
        })
        .catch(() => {
          this.dateSelectLoading = false;
        });
    },
    //获取PM25日周月历史数据
    getHistoryData(fields, frequency, days = 0) {
      this.loadingOptions[fields + "Loading"] = true;
      return this.$http
        .getRangeHistoryData(this.$route.params.asset, days, frequency, fields)
        .then((res) => {
          console.log(res);
          this[fields].x = res.data.xArray.map((item) => {
            if (days === 0) {
              return item.slice(11, 16);
            } else {
              return item.slice(5, 10);
            }
          });
          this[fields].y = res.data.yArray;
          this[fields].nowValue =
            res.data.yArray[res.data.yArray.length - 1] || 0;
          this.loadingOptions[fields + "Loading"] = false;
        })
        .catch(() => {
          this.loadingOptions[fields + "Loading"] = false;
        });
    },
    //获取单个设备状态信息
    getDeviceStatus() {
      this.$http.getSingleDeviceStatus(this.$route.params.asset).then((res) => {
        console.log(res, "status");
        if (res.data.workPattern === 0) {
          res.data.workPattern = 1;
        }
        if (res.data.windOut === 0) {
          res.data.windOut = 1;
        }
        this.singeDeviceStatus = this.newSingeDeviceStatus = { ...res.data };
      });
    },
    //["PM2.5", "CO2", "温度", "湿度", "TVOC"， "甲醛"],
    chartDialogChangeTitle(title) {
      this.chartDialogProperty.loading = true;
      let filed;
      if (title === "PM2.5") {
        filed = "PM25";
      } else if (title === "CO2") {
        filed = "CO2";
      } else if (title === "温度") {
        filed = "wendu";
      } else if (title === "湿度") {
        filed = "shidu";
      } else if (title === "TVOC") {
        filed = "TVOC";
      } else {
        filed = "jiaquan";
      }
      this.$http
        .getRangeData(
          this.$route.params.asset,
          24,
          this.datePick[0],
          this.datePick[1],
          filed
        )
        .then((res) => {
          console.log(res, "dialog");
          // this.chartDialogData = res.data;
          this.chartDialogData.xArray = res.data.xArray.map((item) => {
            return item.slice(5, 10);
          });
          this.chartDialogData.yArray = res.data.yArray;
          this.chartDialogProperty.loading = false;
        })
        .catch(() => {
          this.chartDialogProperty.loading = false;
        });
    },
    closeChartDialog() {
      this.chartDialogShow = false;
    },
    dialogCancel() {
      this.dialogProperty.show = false;
    },
    // dialogConfirm() {
    //   let data =
    //     "" +
    //     this.newSingeDeviceStatus.activeStatus +
    //     this.newSingeDeviceStatus.windOut +
    //     this.newSingeDeviceStatus.workPattern;
    //   this.$http.postDeviceState(this.$route.params.asset, data).then(() => {
    //     this.getDeviceStatus();
    //     this.$message({
    //       type: "success",
    //       message: "操作成功",
    //     });
    //   });
    // },
    dialogConfirm() {
      // if (this.loadingDeviceState) return;
      let data =
        "" +
        this.newSingeDeviceStatus.activeStatus +
        this.newSingeDeviceStatus.windOut +
        this.newSingeDeviceStatus.workPattern;
      console.log(data);
      this.$http
        .getDeviceState(
          this.$route.params.asset,
          "setPower",
          this.newSingeDeviceStatus.activeStatus === 1
        )
        .then((res) => {
          console.log(res, "power");

          if (
            res.data === this.newSingeDeviceStatus.activeStatus &&
            res.data !== 0
          ) {
            this.$http
              .getDeviceState(
                this.$route.params.asset,
                "setMode",
                this.newSingeDeviceStatus.workPattern
              )
              .then((res) => {
                if (
                  res.data === this.newSingeDeviceStatus.workPattern &&
                  res.data === 3
                ) {
                  this.$http
                    .getDeviceState(
                      this.$route.params.asset,
                      "setSpeed",
                      this.newSingeDeviceStatus.windOut
                    )
                    .then((res) => {
                      if (res.data === this.newSingeDeviceStatus.windOut) {
                        this.$http
                          .setDeviceTiming(
                            this.$route.params.asset,
                            this.newSingeDeviceStatus.timingStatus
                          )
                          .then(() => {
                            this.$message({
                              type: "success",
                              message: "操作成功",
                            });
                          });
                      }
                    });
                } else {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                }
              });
          } else if (
            res.data === this.newSingeDeviceStatus.activeStatus &&
            res.data === 0
          ) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
          } else if (res.data !== this.newSingeDeviceStatus.activeStatus) {
            // console.log(res.data, this.newSingeDeviceStatus, "caozuo");
            this.$message({
              type: "error",
              message: "操作失败",
            });
            this.getDeviceStatus();
          }
        });
      // this.$emit("refreshTable");

      this.dialogProperty.show = false;
    },
    setDevice() {
      this.dialogProperty.show = true;
    },
    //查询范围日期数据
    searchRangeData() {
      console.log(this.$route);
      if (this.datePick[0] === this.datePick[1]) return;
      this.chartDialogShow = true;
      this.chartDialogProperty.loading = true;
      this.$http
        .getRangeData(
          this.$route.params.asset,
          24,
          this.datePick[0],
          this.datePick[1],
          "wendu"
        )
        .then((res) => {
          console.log(res);
          // this.chartDialogData = res.data;
          this.chartDialogData.xArray = res.data.xArray.map((item) => {
            return item.slice(5, 10);
          });
          this.chartDialogData.yArray = res.data.yArray;
          this.chartDialogFooter =
            this.datePick[0].slice(0, 11) +
            " - " +
            this.datePick[1].slice(0, 11);
          this.chartDialogProperty.loading = false;
        })
        .catch(() => {
          this.chartDialogProperty.loading = false;
        });
    },
  },
  beforeDestroy() {
    bus.$off("changeDate");
  },
  mounted() {
    bus.$on("changeDate", (e, name) => {
      this.dateBlockStatus = this.lastRequestBlock = null;
      let frequency = 1;
      if (e !== 0) frequency = 24;
      this.selected = 0;
      this.getHistoryData(name, frequency, e);
      this.singeDateBlockStatus[name + "Status"] =
        e === 0 ? 1 : e === 7 ? 2 : 3;
    });
    this.getDeviceStatus();
    this.getRangeData(0);
    // this.getHistoryData("PM25");
    // this.getHistoryData("wendu");
    // this.getHistoryData("shidu");
    // this.getHistoryData("TVOC");
    // this.getHistoryData("CO2");
    // .then((res) => {
    //   console.log(res);
    //   this.loadingOptions.PM25Loading = true;
    //   this[].x = res.data.xArray.map((item) => {
    //     return item.slice(11, 16);
    //   });
    //   this.pm25Data.y = res.data.yArray;
    //   this.pm25Data.nowValue = res.data.yArray[res.data.yArray.length - 1];
    //   this.loadingOptions.PM25Loading = false;
    // })
    // .catch(() => {
    //   this.loadingOptions.PM25Loading = false;
    // });
    // bus.$on("changeTitle", (title) => {
    //   console.log(title);
    //   this.deviceName = title;
    // });
  },
  watch: {
    "$route.params.asset"() {
      this.$router.go(0);
    },
  },
};
</script>

<style scoped lang="scss">
.charts-container {
  width: 100%;
  //min-width: 1220px;
  .charts-header {
    //width: 100%;
    min-width: 1230px;
    height: 70px;
    background-color: #fff;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .breadcrumb-font {
      font-size: 14px;
      //max-width: 100px;
    }
    .scene {
      max-width: 90px;
    }
    .charts-header-left {
      display: flex;
      align-items: center;
      .charts-icon {
        width: 20px;
        height: 18px;
      }
      .air-state {
        width: 80px;
        height: 24px;
        font-size: 14px;
        background-color: #40c057;
        color: #fff;
        border-radius: 4px;
        line-height: 24px;
        text-align: center;
        margin-left: 20px;
      }
      .power-on {
        margin-left: 15px;
        width: 10px;
        height: 10px;
        background-color: #40c057;
        border-radius: 50%;
      }
      .power-off {
        margin-left: 15px;
        width: 10px;
        height: 10px;
        background-color: #999;
        border-radius: 50%;
      }
    }
    .charts-header-right {
      display: flex;
      align-items: center;
      .first-radio {
        margin-right: 10px;
      }
      .el-select {
        width: 70px;
        input {
          padding-right: 10px;
        }
      }
      .last-select {
        width: 90px;
      }
      .confirm-button {
        width: 80px;
        height: 26px;
        background-color: #01cfa5;
        font-size: 14px;
        color: #ffffff;
        border-radius: 2px;
        line-height: 26px;
        text-align: center;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
  .charts-search {
    height: 60px;
    min-width: 1230px;
    background-color: #fff;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    .btns {
      display: flex;
      align-items: center;
      span {
        width: 68px;
        height: 26px;
        margin-left: 14px;
        background-color: #a1a1a1;
        color: #ffffff;
        border-radius: 5px;
        line-height: 26px;
        text-align: center;
        cursor: pointer;
        &:first-child {
          margin-left: 0;
        }
      }
      .btn-selected {
        background-color: #1594fd;
      }
    }
    .date-search {
      display: flex;
      align-items: center;
      margin-left: 120px;
      .date-search-btn {
        margin-left: 16px;
        width: 80px;
        height: 26px;
        background-color: #1594fd;
        color: #ffffff;
        font-size: 14px;
        line-height: 26px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .charts-boxes {
    display: flex;
    margin-top: 20px;
    width: 100%;
    .left-boxes {
      display: flex;
      flex-wrap: wrap;
      //width: 1100px;
      width: 100%;
      min-width: 1330px;
      //margin-right: 30px;
      .box-content {
        width: 300px;
        height: 300px;
      }
    }
  }
}
</style>
